import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '@/components/Welcome.vue'
import Games from '@/components/Games.vue'
import Contact from '@/components/Contact.vue'
import SqueezePage from '@/components/SqueezePage.vue'

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/games',
    name: 'Games',
    component: Games
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/sp',
    name: 'SP',
    component: SqueezePage
  }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router