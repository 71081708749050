<template>
  <div id="welcome">
    <h1>Welcome</h1>
    <p>to Type 3 Studios, purveyors of fine games, such as...</p>
	<Games only-first @navigate="navigateTo($event)"/>
  </div>
</template>

<script>
import Games from './Games.vue'

export default {
  name: 'Welcome',
  methods: {
    navigateTo(componentName) {
      this.$emit("navigate", componentName);
    },
  },
  components: {
    Games,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#welcome {
  font-size: 25px;
}
</style>