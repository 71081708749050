<template>
  <div id="contact">
	<MailingList/>
    <h1>Contact</h1>
    <p>The best way to contact us is to join our <a href="https://discord.gg/D6BuHNhXHJ" target="_blank">Discord server</a>.</p>
    <p>You can also send an email to <a href="mailto:info@type3studios.com">info@type3studios.com</a>.</p>
    <h1>Press</h1>
    <p>You can find a Press Kit for Super Marxist Twins <a href="https://www.igdb.com/games/super-marxist-twins/presskit">here</a>.</p>
    <p>For other inquiries, contact us at one of the methods above.</p>
  </div>
</template>

<script>
import MailingList from './MailingList.vue'

export default {
  name: 'Contact',
  components: {
    MailingList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contact {
  width: 50%;
  margin: auto;
  font-size: 25px;
}

a {
  color: var(--text-color);
  vertical-align: middle;
  cursor: pointer;
  padding: 0 2px;
  color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}

a:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 1600px) {
  #contact {
    width: 65%;
  }
}

@media (max-width: 1400px) {
  #contact {
    width: 70%;
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  #contact {
    width: 75%;
    font-size: 23px;
  }
}

@media (max-width: 1000px) {
  #contact {
    width: 80%;
    font-size: 22px;
  }
}

@media (max-width: 800px) {
  #contact {
    width: 85%;
    font-size: 21px;
  }
}

@media (max-width: 600px) {
  #contact {
    width: 90%;
    font-size: 20px;
  }
}

@media (max-width: 400px), (min-resolution: 2dppx) {
  #contact {
    width: 100%;
    font-size: 20px;
  }
}
</style>