<template>
  <div id="squeeze">
    <div class="ml-embedded" data-form="Ux67wH"></div>
  </div>
</template>

<script>
export default {
  name: 'SqueezePage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#squeeze {
  width: 100%;
  height: 130%;
  margin: auto;
  font-size: 25px;
  background-image: url("../assets/sp-bg.jpg");
  background-size: cover;
  background-position: center;
}


#squeeze .ml-embedded {
  margin: auto;
  padding-top: 10%;
}
</style>