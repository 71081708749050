<template>
  <div id="mailinglist">
    <h1>Join Our Mailing List</h1>
    <!-- <div class="ml-embedded" data-form="Ux67wH"></div> -->
    <!-- <button class="ml-onclick-form" onclick="ml('show', 'Ux67wH', true)">Click here to show form</button> -->
    <a class="ml-onclick-form" href="javascript:void(0)" onclick="ml('show', 'Ux67wH', true)"><img src="../assets/mail36x36.png" alt="Open Mailing List Form"></a>
  </div>
</template>

<script>

export default {
  name: 'MailingList'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mailinglist {
  width: 50%;
  margin: auto;
  font-size: 25px;
}

a {
  display: inline-block;
  height: 36px;
  padding: 4px;
  color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}

a img {
  vertical-align: middle;
}

a:hover {
  background-color: var(--secondary-color);
}
</style>